<template>
  <v-card>
    <v-col>
      <v-card-title class="header">{{ propertyTitle }}</v-card-title>
    </v-col>
    <v-col>
      <v-card-subtitle>{{ FLEXPARAMETER.description }}</v-card-subtitle>
    </v-col>
    <v-col>
      <v-card-text>
        <span style="font-weight: bold">{{ FLEXPARAMETER.propertyValue }}</span>
        <span v-show="newPropertyValue.length > 0 && !edited">
          - это предыдущее значение</span
        >
        <span v-show="edited">- изменено</span>
      </v-card-text>
    </v-col>
    <v-col>
      <v-card-text>
        <v-text-field
          v-model="newPropertyValue"
          clearable
          autofocus
          :placeholder="'изменить ' + FLEXPARAMETER.propertyName"
        />
      </v-card-text>
    </v-col>
    <v-col>
      <v-card-actions v-show="newPropertyValue.length > 0">
        <v-btn @click="methodEditProperty">Изменить</v-btn>
      </v-card-actions>
    </v-col>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    propertyName: {
      type: String,
      required: true,
    },
    propertyTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPropertyValue: "",
      edited: false,
    };
  },
  computed: {
    ...mapGetters({
      FLEXPARAMETER: "FlexParameter/STATE",
    }),
  },
  async created() {
    this.getProperty(this.propertyName);
  },
  mounted() {},

  methods: {
    ...mapActions({
      getProperty: "FlexParameter/GET_PROPERTY",
      editProperty: "FlexParameter/EDIT_PROPERTY",
    }),
    methodEditProperty() {
      this.edited = this.editProperty(this.newPropertyValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
