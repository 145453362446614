<template>
  <v-container>
    <loader v-if="loading" />
    <form-for-flex-paramerter
      v-if="!loading"
      property-name="sms_code_text"
      :property-title="title"
    />
  </v-container>
</template>

<script>
import FormForFlexParamerter from "../components/flexParameters/formForFlexParamerter";
import Loader from "../components/Loader.vue";
export default {
  components: { FormForFlexParamerter, Loader },
  data() {
    return {
      loading: false,
      title: this.$route.name,
    };
  },
  async created() {
    await this.checkPowers();
  },

  methods: {
    checkPowers() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
        this.loading = false;
      }
    },
  },
};
</script>
